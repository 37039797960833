.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e8f1e9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.vcenter {

  vertical-align: middle;
  
     display: flex;
     flex-wrap: wrap ;
     justify-content: center; 
  align-items: center;
  }

  
br {
	line-height:2em;/* chrome for instance */
}

/* a:link { text-decoration: none; color: #ffffff;} */

a.download, a.download:hover,  a.download:visited { text-decoration: none; color:rgba(170, 2, 67, 1);} 




/*  */

.spacerx {
	height: 100px;
}


.hovereflow  {
	
	overflow: hidden;

}

.roundborder {
	background: rgba(255,255,255,0.96);
	box-shadow: 10px 10px 10px rgba(0,33,81,0.5);
	padding-left: 10px;
	padding-right: 10px;
  border-radius: 20px; 
}



.cx {
	background-color: rgba(170, 2, 67, 1);
}

h1 {
  color: #002151;
}

.DelelteTempStyle {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: aqua;
  position: relative;
}