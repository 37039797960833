body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #002151;

}

html, body {
  height: 100%;
}

.full-height {
  height: 100%;
}
